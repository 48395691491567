<template>
  <div class="bread-shell flex-between">
    <div>
      <span class="bread-title">{{ currentName }}</span>
    </div>
    <div class="flex-start">
      <span>您的位置：</span>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ currentMenu.name }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ currentName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>
<script>
import {StorageUtil} from '/utils/util.js'
export default {
  name: 'BreadCrumb',
  props: {},
  data() {
    return {
      currentMenu: {},
      currentName: '',
      currentPage: {},
    }
  },
  props: {
    // intervalUpdate: {    //定义onOK属性
    //     type: Function
    // }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.currentMenu = StorageUtil.get('currentRouter')
      this.currentPage= StorageUtil.get('currentPage')

      if (this.currentMenu.children && this.currentMenu.children.length) {
        // debugger
        // let name = this.currentMenu.children.find((menu) => menu.show)?.name
        let name = this.currentPage.name;
        if (!name) {
          name = this.currentMenu.children[0].name
        }
        this.currentName = name
      }
    },
  },
}
</script>
<style scoped lang="less">
.bread-shell {
  width: 100%;
  height: 65px;
  padding-top: 40px;
  border-bottom: 1px solid #ddd;
  .bread-title {
    color: #cc3333;
    font-size: 18px;
    font-weight: bold;
    padding-left: 14px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 4px;
      display: block;
      width: 3px;
      height: 20px;
      background: #cc3333;
    }
  }
}
</style>
