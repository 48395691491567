<template>
  <div class="container" >
    <!-- header :style="{ width: clintWidth + 'px', height: clintHeight + 'px' }" -->
    <div>
      <HeaderTop @navClick="$refs.MenuListRef.init();$refs.BreadCrumbRef.init()"/>
    </div>
    <div class="container-shell">
      <div class="sub-banner"><img src="~@/assets/img/subBanner3.png" alt="" class="width-fill height-fill"></div>

      <div class="right-shell">
        <MenuList ref="MenuListRef" @menuClick="$refs.BreadCrumbRef.init()"/>
        <div class="content-shell">
          <BreadCrumb ref="BreadCrumbRef"/>
					<div class="content">
						<div class="flex-between margin-top-xl">
              <div class="flex-start">
                <div class="margin-right-xl ">
                  所属城市
                <el-select placeholder="请选择所属城市" v-model="queryParams.city" class="select-custom" clearable>
                  <el-option
                    v-for="item in options1"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue">
                  </el-option>
                </el-select>
                </div>
                <div>
                  产业方向
                <el-select placeholder="请选择产业方向" v-model="queryParams.type" class="select-custom" clearable>
                  <el-option
                    v-for="item in options2"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue">
                  </el-option>
                </el-select>
                </div>
              </div>
           <!--   <div class="flex check-group">
                <span class="margin-right-xl flex" @click="checkChange('0')"><i class="check-style" :class="pilotCities == '0' && 'active'"></i>国家试点城市</span>
                <span class="flex" @click="checkChange('1')"><i class="check-style" :class="pilotCities == '1' && 'active'"></i>山东省试点城市</span>
              </div> -->
			  <el-button style="background-color: #CC3333; border: 0;color: white;" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>

            </div>
            <div class="img-shell">
                <div class="map-city"></div>
                <div class="img-content"  v-for="(city, i) in cityMaps" :key="i" :style="`top:${city.ex1}px;left: ${city.ex2}px`">

                    <div class="img-text flex-between">
                        <span>{{city.cityName}}</span>
                        <span>({{city.exnum == 0 ? '' : city.exnum}})</span>
                    </div>
                </div>
            </div>
            <div>
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column prop="name" label="企业名称" headerAlign="center" align="center">
                    </el-table-column>
                    <el-table-column prop="cityName" label="所属地市或省属企业" headerAlign="center"  align="center">
                    </el-table-column>
                    <el-table-column prop="typeName" label="产业方向" headerAlign="center"  align="center">
                    </el-table-column>
                 <!--   <el-table-column  label="操作" width="100" headerAlign="center"  align="center">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
                <div class="flex-between margin-top-sm">
                    <span></span>
               <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :hide-on-single-page="true" background layout="prev, pager, next" :page-size="queryParams.pageSize" :total="total" > </el-pagination>
                </div>

				   </div>

					</div>
        </div>
      </div>
      <BottomFooter />
    </div>

  </div>
</template>
<script>
import HeaderTop from "@/components/parts/headerTop";
import BottomFooter from "@/components/parts/bottomFooter";
import MenuList from "@/components/parts/menuList";
import BreadCrumb from "@/components/parts/BreadCrumb";
import { getlistExperimental,getlistExperimentalNum,getlistCity,getlistDirection} from '@/api/api'
export default {
  name: "detail2",
  data() {
    return {
      clintWidth: 1920,
      clintHeight: 1080,
      form: {
        title: '来访交流 | 广东省职业能力建设协会到促进会交流',
        auther: '张三',
        type: '企业动态',
        dateT: '2023/04/21 16:00',

      },
      cityMaps: [
      ],
      pilotCities: '0',
      options1: [],
		options2: [],
      value: '',
      tableData:[

      ],
	  queryParams: {
	    pageNum: 1,
	    pageSize: 10,
		type:'',
		city:''
	  },
	  total:0,
	  currentPage:1,
      tab: 0
    }
  },
  components: {
    HeaderTop,
    BottomFooter,
    MenuList,
    BreadCrumb
  },
  mounted() {
    // this.resize();


  },
created() {
	this.getlistExperimental();
	this.getlistCity();
	this.getlistDirection();
	this.getlistExperimentalNum();
},
  methods: {
	  handleQuery() {
	    this.queryParams.pageNum = 1;
	    this.getlistExperimental();
		this.getlistExperimentalNum();
	  },
	  getlistCity(){
	  	getlistCity().then(res => {
	  	this.options1 = res.rows;
	  	})
	  },
	  getlistDirection(){
	  	getlistDirection().then(res => {
	  	this.options2 = res.rows;
	  	})
	  },
    checkChange(index){
        this.pilotCities = index;
    },
    getlistExperimentalNum(){
    	getlistExperimentalNum(this.queryParams).then(res => {
    	this.cityMaps = res.rows;
    	})
    },
	getlistExperimental(){
		getlistExperimental(this.queryParams).then(res => {
		this.tableData = res.rows;
		this.total = res.total;
		})
	},
	handleCurrentChange(currentPage) {
		this.queryParams.pageNum = currentPage;
		 getlistExperimental(this.queryParams).then(res => {
				this.tableData = res.rows;
				this.total = res.total;
		 })
	}

  },

};
</script>
<style scoped lang="less">
  .container {
		width: 100%;
		//min-height: 100vh;
    .container-shell {
      //height: calc(100% - 0);
      overflow: scroll;
    }
    .sub-banner{
      width: 100%;
      height: 248px;
    }
    .right-shell{
      width: 1420px;
      min-height: 560px;
      padding-left: 300px;
      position: relative;
      margin: 0 auto;
      & .content-shell{
        width: 100%;
        height: 100%;
    		.content {
    			width: 100%;
    			height: calc(100% - 65px);
					overflow-x: hidden;
    			overflow-y: scroll;
					.img-shell{
            width: 100%;
            height: 510px;
            background: url("@/assets/img/map2.png") no-repeat center center;
            background-size: auto 86%;
            position: relative;
            .map-city{
              position: absolute;
              bottom: 30px;
              right: 0;
              width: 112px;
              height: 235px;
              background: url("@/assets/img/map-city.png")no-repeat center center;
              background-size: auto 100%;
            }
          }
          .img-content{
            position: absolute;
            top: 200px;
            left: 430px;
            color: #fff;
            width: 12px;
            height: 14px;
            background: url("@/assets/img/map-point.png")no-repeat left center;
            background-size: auto 100%;
            & .img-text {
              position: absolute;
              top: -35px;
              left: -30px;
              background: rgba(34, 104, 161, 0.7);
              padding: 0 10px;
              width: 70px;
              border: 1px solid #96DAFF;
              font-size: 12px;
              line-height: 1.2em;
              border-radius: 4px;
            }
          }
          /deep/ .el-input__inner{
            background: transparent;
            // color: #fff;
            border-color: #CC3333
          }
          /deep/ .el-select__caret{
            color: #CC3333;
          }
          .check-group{
            font-size: 16px;
            .check-style{
              display: inline-block;
              margin-right: 4px;
              width: 26px;
              height: 26px;
              background: url("@/assets/img/check.png");
              background-size: 100% 100% ;
              cursor: pointer;
              &.active{
                background: url("@/assets/img/check-active.png");
                background-size: 100% 100% ;
              }
            }
          }
          /deep/ .el-table thead tr th.el-table__cell{
            background: #CC3333;
            color: #fff;
            font-size: 18px;
          }
          /deep/ .el-pagination{
            &.is-background .btn-next, &.is-background .btn-prev, &.is-background .el-pager li {
              background-color: transparent;
              border: 1px solid #DDD;
              font-weight: 400;
            }
            &.is-background .el-pager li:not(.disabled).active{
              background-color: #CC3333;
              border: none;
            }
          }
          /deep/ .el-button--text{
            color: #CC3333;
          }


    		}
      }
    }
  }

</style>
