<template>
  <div class="container" >
    <!-- header :style="{ width: clintWidth + 'px', height: clintHeight + 'px' }" -->
    <div>
      <HeaderTop @navClick="$refs.MenuListRef.init();$refs.BreadCrumbRef.init()"/>

    </div>
    <div class="container-shell">
      <div class="sub-banner"><img src="~@/assets/img/subBanner1.png" alt="" class="width-fill height-fill"></div>
      <div class="right-shell">
        <MenuList ref="MenuListRef" @menuClick="$refs.BreadCrumbRef.init()"/>
        <div class="content-shell">
          <BreadCrumb ref="BreadCrumbRef"/>
					<div class="padding-top-xl content">
						<div class="list-shell flex-wrap">
							<div class="item-shell flex-column" v-for="item in newsList" :key="item.id">
                <div class="img-shell" :style="{backgroundImage: `url(${ imgBaseUrl.imgBaseUrl+item.photo })`}">
<!--                  <img :src="imgBaseUrl.imgBaseUrl+item.photo" alt="" />-->
                </div>
								<div class="item-name flex-center" >{{item.name}}</div>
								<div class="item-title flex-center" style="font-size: 13px;" v-for="t in item.desc">{{t}}</div>
							</div>
						</div>
					</div>
					<div class="flex-between margin-top-sm">
						<span></span>
						<el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :hide-on-single-page="true" background layout="prev, pager, next" :page-size="queryParams.pageSize" :total="total" > </el-pagination>
					</div>

        </div>
      </div>
      <BottomFooter />
    </div>

  </div>
</template>
<script>
import HeaderTop from "@/components/parts/headerTop";
import BottomFooter from "@/components/parts/bottomFooter";
import MenuList from "@/components/parts/menuList";
import BreadCrumb from "@/components/parts/BreadCrumb";
import { getlistExpert } from '@/api/api';
import imgBaseUrl from '/utils/imgBaseUrl';
export default {
  name: "listExpert",
  data() {
    return {
		queryParams: {
		  pageNum: 1,
		  pageSize: 8

		},
		total:0,
		currentPage:1,
      imgBaseUrl,
      clintWidth: 1920,
      clintHeight: 1080,
      newsList: [

      ],

      tab: 0
    }
  },
  components: {
    HeaderTop,
    BottomFooter,
    MenuList,
    BreadCrumb
  },
  mounted() {
    // this.resize();


  },
  created(){
  	  this.getlistExpert();
  },

  methods: {
	  handleCurrentChange(currentPage) {
	  	this.queryParams.pageNum = currentPage;
	  	 getlistExpert(this.queryParams).then(res => {
	  			this.newsList = res.rows;
	  			this.total = res.total;
	  	 })
	  },
	  getlistExpert(){
	    getlistExpert(this.queryParams).then(res => {
	  	this.newsList = res.rows;
		this.total = res.total;
	    })
	  },

  },
  destroyed() {

  }
};
</script>
<style scoped lang="less">
	/deep/ .el-pagination{
	  &.is-background .btn-next, &.is-background .btn-prev, &.is-background .el-pager li {
	    background-color: transparent;
	    border: 1px solid #DDD;
	    font-weight: 400;
	  }
	  &.is-background .el-pager li:not(.disabled).active{
	    background-color: #CC3333;
	    border: none;
	  }
	}
  .container {
		width: 100%;
		min-height: 100vh;
    .container-shell {
      height: calc(100% - 0);
      overflow-y: scroll;
    }
    .sub-banner{
      width: 100%;
      height: 248px;
    }
    .right-shell{
      width: 1420px;
      min-height: 560px;
      padding-left: 300px;
      position: relative;
      margin: 0 auto;
      & .content-shell{
        width: 100%;
        height: 100%;
    		.content {
    			width: 100%;

					.list-shell{
						overflow-x: hidden;
						width: calc(100% + 68px);
						.item-shell{
							width: 230px;
							height: 386px;
							margin-right: 67px;
              margin-bottom: 20px;
							.img-shell {
								width: 230px;
								height: 280px;
                background-size: cover;
                background-position: center;
                //img{
                //  min-width:  100%;
                //  min-height: 100%;
                //}
							}
							.item-name {
								font-size: 18px;
							}
							.item-title {
                  text-align: center;
							}
						}
					}
    		}
      }
    }
  }

</style>
