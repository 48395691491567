import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index'
import sysInfo from '@/views/sysInfo'
import Constitution from '@/views/Constitution'
import listExpert from '@/views/listExpert'
import listNotice from '@/views/listNotice'
import listAssociation from '@/views/listAssociation'
import listMembershipStyle from '@/views/listMembershipStyle'
import listMembershipStyleDetail from '@/views/listMembershipStyleDetail'
import listMembershipStyle2 from '@/views/listMembershipStyle2'
import detail1 from '@/views/detail1'
import listExperimental from '@/views/listExperimental'
import listCompany from '@/views/listCompany'
import listCompanyDetail from '@/views/listCompanyDetail'
import conact from '@/views/conact'
import Institution from '@/views/Institution'
import Specialization from '@/views/Specialization'
import Address from '@/views/Address'
import listIndedu from '@/views/listIndedu'
import listNoticeDetail from '@/views/listNoticeDetail'
import listAssociationDetail from '@/views/listAssociationDetail'
import listIndeduDetail from '@/views/listIndeduDetail'
import listPolicyStatute from '@/views/listPolicyStatute'
import listPolicyStatuteDetail from '@/views/listPolicyStatuteDetail'
import listSpecial from '@/views/listSpecial'
import listSpecialDetail from '@/views/listSpecialDetail'
import listBuilding from '@/views/listBuilding'
import listBuildingDetail from '@/views/listBuildingDetail'
import listIndustry from '@/views/listIndustry'
import listIndustryDetail from '@/views/listIndustryDetail'
import listAcademy from '@/views/listAcademy'
import listAcademyDetail from '@/views/listAcademyDetail'
import listDownload from '@/views/listDownload'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
}
const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/sysInfo',
    name: 'sysInfo',
    component: sysInfo
  },
  {
    path: '/Institution',
    name: 'Institution',
    component: Institution
  },
  {
    path: '/Constitution',
    name: 'Constitution',
    component: Constitution
  },
	{
	  path: '/listExpert',
	  name: 'listExpert',
	  component: listExpert
	},
	{
	  path: '/Specialization',
	  name: 'Specialization',
	  component: Specialization
	},
	,
	{
	  path: '/Address',
	  name: 'Address',
	  component: Address
	},
  {
	  path: '/listNotice',
	  name: 'listNotice',
	  component: listNotice
	},
	{
	  path: '/listAssociation',
	  name: 'listAssociation',
	  component: listAssociation
	},
	{
	  path: '/listIndedu',
	  name: 'listIndedu',
	  component: listIndedu
	},
	{
		  path: '/listNoticeDetail/:id',
		  name: 'listNoticeDetail',
		  component: listNoticeDetail
		},
		{
		  path: '/listAssociationDetail/:id',
		  name: 'listAssociationDetail',
		  component: listAssociationDetail
		},
		{
		  path: '/listIndeduDetail/:id',
		  name: 'listIndeduDetail',
		  component: listIndeduDetail
		},
		{
		  path: '/listPolicyStatute',
		  name: 'listPolicyStatute',
		  component: listPolicyStatute
		},
		{
			  path: '/listPolicyStatuteDetail/:id',
			  name: 'listPolicyStatuteDetail',
			  component: listPolicyStatuteDetail
		},
		{
		  path: '/listSpecial',
		  name: 'listSpecial',
		  component: listSpecial
		},
		{
			  path: '/listSpecialDetail/:id',
			  name: 'listSpecialDetail',
			  component: listSpecialDetail
		},
		{
		  path: '/listBuilding',
		  name: 'listBuilding',
		  component: listBuilding
		},
		{
			  path: '/listBuildingDetail/:id',
			  name: 'listBuildingDetail',
			  component: listBuildingDetail
		},
	{
	  path: '/listMembershipStyle',
	  name: 'listMembershipStyle',
	  component: listMembershipStyle
	},
	{
		  path: '/listMembershipStyleDetail/:id',
		  name: 'listMembershipStyleDetail',
		  component: listMembershipStyleDetail
	},
	{
	  path: '/listMembershipStyle2',
	  name: 'listMembershipStyle2',
	  component: listMembershipStyle2
	},
	{
	  path: '/detail1',
	  name: 'detail1',
	  component: detail1
	},
	{
	  path: '/listExperimental',
	  name: 'listExperimental',
	  component: listExperimental
	},
	{
		path: '/listCompany',
		name: 'listCompany',
		component: listCompany
	},
	{
		path: '/listCompanyDetail',
		name: 'listCompanyDetail',
		component: listCompanyDetail
	},
	{
		path: '/conact',
		name: 'conact',
		component: conact
	},
	{
	  path: '/listIndustry',
	  name: 'listIndustry',
	  component: listIndustry
	},
	{
		  path: '/listIndustryDetail/:id',
		  name: 'listIndustryDetail',
		  component: listIndustryDetail
	},

	{
	  path: '/listAcademy',
	  name: 'listAcademy',
	  component: listAcademy
	},
	{
		  path: '/listAcademyDetail/:id',
		  name: 'listAcademyDetail',
		  component: listAcademyDetail
	},
	{
		  path: '/listDownload',
		  name: 'listDownload',
		  component: listDownload
	}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
