<template>
  <div class="container" >
    <!-- header :style="{ width: clintWidth + 'px', height: clintHeight + 'px' }" -->
    <div>
      <HeaderTop @navClick="$refs.MenuListRef.init();$refs.BreadCrumbRef.init()"/>

    </div>
    <div class="container-shell">
      <div class="sub-banner"><img src="~@/assets/img/subBanner6.png" alt="" class="width-fill height-fill"></div>
      <div class="right-shell">
        <MenuList ref="MenuListRef" @menuClick="$refs.BreadCrumbRef.init()"/>
        <div class="content-shell">
          <BreadCrumb ref="BreadCrumbRef"/>
					<div class="content" v-loading="loading">
						<div class="text-title">{{ form.title }}</div>
						<div class="text-desc flex-center">
            <!--  <span class="margin-right-xl">发布作者：{{ form.auther }}</span>
              <span class="margin-right-xl">类型：{{ form.type }}</span> -->
              <span>发布时间：{{ form.createTime }}</span>
            </div>
            <div class="text-content ql-editor" v-html="form.content"></div>
					</div>
        </div>
      </div>
      <BottomFooter />
    </div>

  </div>
</template>
<script>
import HeaderTop from "@/components/parts/headerTop";
import BottomFooter from "@/components/parts/bottomFooter";
import MenuList from "@/components/parts/menuList";
import BreadCrumb from "@/components/parts/BreadCrumb";
import { getIndustry } from '@/api/api'
export default {
  name: "listNoticeDetail",
  data() {
    return {
			loading:true,
      clintWidth: 1920,
      clintHeight: 1080,
      form: {

	  },

      tab: 0
    }
  },
  components: {
    HeaderTop,
    BottomFooter,
    MenuList,
    BreadCrumb
  },
  mounted() {
    // this.resize();
      window.scrollTo(0, 0);

  },
  created() {
  	const id = this.$route.params.id;
	this.getIndustry(id);
  },
  methods: {
	  getIndustry(id){
		getIndustry(id).then(res => {
		this.form = res.data;
			this.loading = false;
		})
	  },
  },
  destroyed() {

  }
};
</script>
<style scoped lang="less">
  .container {
		width: 100%;
		min-height: 100vh;
    .container-shell {
      height: calc(100% - 0);
      overflow-y: scroll;
    }
    .sub-banner{
      width: 100%;
      height: 248px;
    }
    .right-shell{
      width: 1420px;
      min-height: 560px;
      padding-left: 300px;
      position: relative;
      margin: 0 auto;
      & .content-shell{
        width: 100%;
        height: 100%;
    		.content {
    			width: 100%;
    			// height: calc(100% - 65px);
					// overflow-x: hidden;

					.text-title{
            font-size: 24px;
            font-weight: 700;
            margin-top: 20px;
            text-align: center;
          }
          .text-desc {
            font-size: 16px;
            color: #888;
            margin: 20px 0;
          }
          .text-content{
            line-height: 1.75em;
            :deep(p) {
              text-indent: 2em;
              margin-top: 10px;
            }
            :deep(img) {
              max-width: 100%;
            }
          }
    		}
      }
    }
  }

</style>
