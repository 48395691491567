import { render, staticRenderFns } from "./listSpecial.vue?vue&type=template&id=3975133c&scoped=true"
import script from "./listSpecial.vue?vue&type=script&lang=js"
export * from "./listSpecial.vue?vue&type=script&lang=js"
import style0 from "./listSpecial.vue?vue&type=style&index=0&id=3975133c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3975133c",
  null
  
)

export default component.exports