<template>
  <div class="container" >
    <!-- header :style="{ width: clintWidth + 'px', height: clintHeight + 'px' }" -->
    <div>
      <HeaderTop @navClick="$refs.MenuListRef.init();$refs.BreadCrumbRef.init()"/>
    </div>
    <div class="container-shell">
      <div class="sub-banner"><img src="~@/assets/img/subBanner1.png" alt="" class="width-fill height-fill"></div>

      <div class="right-shell">
        <MenuList ref="MenuListRef" @menuClick="$refs.BreadCrumbRef.init()"/>
        <div class="content-shell">
          <BreadCrumb ref="BreadCrumbRef"/>
					<div class="content">
            山东省产教融合促进会
            地址：山东省济南市历下区泺源大街66号
            电话：(0531)86065990
            Email：aaaaaaa@163.com
					</div>
        </div>
      </div>
    </div>
    <BottomFooter />
  </div>
</template>
<script>
import HeaderTop from "@/components/parts/headerTop";
import BottomFooter from "@/components/parts/bottomFooter";
import MenuList from "@/components/parts/menuList";
import BreadCrumb from "@/components/parts/BreadCrumb";
// import DynamicMarquee from "vue-dynamic-marquee";
// import { getEqpTotal, getEqpList, getOkNgOutput, getEqpOkNgOutput, getHisEqpShiftList, getOeeEqpOutputList, getHisOeeEqpHisHourseOutput } from '@/api/api'
export default {
  name: "detail3",
  data() {
    return {
      clintWidth: 1920,
      clintHeight: 1080,
      form: {
        title: '来访交流 | 广东省职业能力建设协会到促进会交流',
        auther: '张三',
        type: '企业动态',
        dateT: '2023/04/21 16:00',

      },
      count: 123,
      pilotCities: '0',
      options: [
          {
        value: '选项1',
        label: '黄金糕'
        }, {
        value: '选项2',
        label: '双皮奶'
        }, {
        value: '选项3',
        label: '蚵仔煎'
        }, {
        value: '选项4',
        label: '龙须面'
        }, {
        value: '选项5',
        label: '北京烤鸭'
        }],
      value: '',
      tableData:[
          {
              name: '王小虎',
              province: '上海',
              city: '普陀区',
          }
      ],
      tab: 0
    }
  },
  components: {
    HeaderTop,
    BottomFooter,
    MenuList,
    BreadCrumb
  },
  mounted() {
    // this.resize();


  },

  methods: {
    checkChange(index){
        this.pilotCities = index;
    },
    handleClick(){}
  },
  destroyed() {

  }
};
</script>
<style scoped lang="less">
  .container {
		width: 100%;
		min-height: 100vh;
    .container-shell {
      height: calc(100% - 0);
      overflow: scroll;
    }
    .sub-banner{
      width: 100%;
      height: 248px;
    }
    .right-shell{
      width: 1420px;
      min-height: 560px;
      padding-left: 300px;
      position: relative;
      margin: 0 auto;
      & .content-shell{
        width: 100%;
        height: 100%;
    		.content {
    			width: 100%;
    			height: calc(100% - 65px);
					overflow-x: hidden;
    			overflow-y: scroll;

          .img-text{
            position: absolute;
            top: 200px;
            left: 430px;
            color: #CC3333;
            font-weight: 700;
          }
          /deep/ .el-input__inner{
            background: transparent;
            color: #fff;
            border-color: #CC3333
          }
          /deep/ .el-select__caret{
            color: #CC3333;
          }

          /deep/ .el-table thead tr th.el-table__cell{
            background: #CC3333;
            color: #fff;
            font-size: 18px;
          }
          /deep/ .el-pagination{
            &.is-background .btn-next, &.is-background .btn-prev, &.is-background .el-pager li {
              background-color: transparent;
              border: 1px solid #DDD;
              font-weight: 400;
            }
            &.is-background .el-pager li:not(.disabled).active{
              background-color: #CC3333;
              border: none;
            }
          }
          /deep/ .el-button--text{
            color: #CC3333;
          }
          /deep/ .el-input{
            width: 210px;
            height: 33px;
            & .el-input__inner{
              height: 100%;
            }
          }

    		}
      }
    }
  }

</style>
