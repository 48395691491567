const formatTime = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}
function getTime(time, formater) {
  // 转换为式分秒
  let h = parseInt(time / 60 / 60 % 24)
  h = h < 10 ? '0' + h : h
  let m = parseInt(time / 60 % 60)
   m = m < 10 ? '0' + m : m
  let s = parseInt(time % 60)
   s = s < 10 ? '0' + s : s
  // 作为返回值返回
  return formater == 'mm:ss' ? [m, s].map(formatNumber).join(':') : [h, m, s].map(formatNumber).join(':')
}
const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
function isEmpty(obj){
  for(let key in obj){
      return false;//若不为空，可遍历，返回false
  }
  return true;
}
StorageUtil = {
  // 存储localStorage,如果提供类型则存放在sessionStorage中
  set: (key, value, type, datetime) => {
    const obj = {
      dataType: typeof value,
      value: value,
      type: type,
      datatime: datetime || new Date().getTime(),
    };
    if (type) window.sessionStorage.setItem(key, JSON.stringify(obj));
    else window.localStorage.setItem(key, JSON.stringify(obj));
  },
  get: (key, defaultValue) => {
    let value;
    let obj = window.localStorage.getItem(key) || {};
    if (isEmpty(obj)) {
      obj = window.sessionStorage.getItem(key);
    }
    if (isEmpty(obj)) {
      return defaultValue;
    } //默认为undefined
    obj = JSON.parse(obj);
    switch (obj.dataType) {
      case "string":
        value = obj.value;
        break;
      case "number":
        value = Number(obj.value);
        break;
      case "boolean":
        value = Boolean(obj.value);
        break;
      case "object":
        value = obj.value;
        break;
    }
    return value;
  },
 
  remove: key => {
    window.sessionStorage.removeItem(key);
    window.localStorage.removeItem(key);
  },
  clear: () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
  },
};
module.exports = {
  formatTime,
  formatNumber,
  getTime,
  StorageUtil
}

