
<template>
  <div class="container" >
    <!-- header :style="{ width: clintWidth + 'px', height: clintHeight + 'px' }" -->
    <div>
      <HeaderTop @navClick="$refs.MenuListRef.init();$refs.BreadCrumbRef.init()"/>
    </div>
    <div class="container-shell">
      <div class="sub-banner"><img src="~@/assets/img/subBanner5.png" alt="" class="width-fill height-fill"></div>

      <div class="right-shell">
        <MenuList ref="MenuListRef" @menuClick="$refs.BreadCrumbRef.init()"/>
        <div class="content-shell">
          <BreadCrumb ref="BreadCrumbRef"/>
					<div class="content">

            <div class="margin-top-xl">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column prop="title" label="企业名称" headerAlign="center" align="center">
                    </el-table-column>
                    <el-table-column prop="area" label="所属区域" headerAlign="center"  align="center">
                    </el-table-column>
                    <el-table-column prop="type" label="类别" headerAlign="center"  align="center">
						<template slot-scope="scope">
                              <el-tag v-if="scope.row.type==0"  effect="dark">企业</el-tag>
                              <el-tag v-else-if="scope.row.type==1"  effect="dark">院校</el-tag>
                        </template>
					</el-table-column>
					<el-table-column label="入会时间" align="center" prop="joinTime" >
					</el-table-column>
                   <!-- <el-table-column  label="操作" width="100" headerAlign="center"  align="center">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                        </template>
                   	</el-table-column> -->
                </el-table>
                <div class="flex-between margin-top-sm">
                    <span></span>
                    <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :hide-on-single-page="true" background layout="prev, pager, next" :page-size="10" :total="total" > </el-pagination>

				</div>

            </div>

					</div>
        </div>
      </div>
    </div>
    <BottomFooter />
  </div>
</template>
<script>
import HeaderTop from "@/components/parts/headerTop";
import BottomFooter from "@/components/parts/bottomFooter";
import MenuList from "@/components/parts/menuList";
import BreadCrumb from "@/components/parts/BreadCrumb";
import { getlistMembershipStyle } from '@/api/api'
// import DynamicMarquee from "vue-dynamic-marquee";
// import { getEqpTotal, getEqpList, getOkNgOutput, getEqpOkNgOutput, getHisEqpShiftList, getOeeEqpOutputList, getHisOeeEqpHisHourseOutput } from '@/api/api'
export default {
  name: "detail3",
  data() {
    return {
      clintWidth: 1920,
      clintHeight: 1080,
      form: {

      },
      count: 123,
      pilotCities: '0',
      options: [
         ],
      value: '',
      tableData:[
      ],
	  queryParams: {
	    pageNum: 1,
	    pageSize: 10
	  },
	  total:0,
	  currentPage:1,
      tab: 0
    }
  },
  components: {
    HeaderTop,
    BottomFooter,
    MenuList,
    BreadCrumb
  },
  mounted() {
    // this.resize();


  },
 created(){
 	  this.getlistMembershipStyle();
 },
  methods: {
    checkChange(index){
        this.pilotCities = index;
    },
	getlistMembershipStyle(){
	  getlistMembershipStyle(this.queryParams).then(res => {
		this.tableData = res.rows;
		this.total = res.total;
	  })
	},
	handleCurrentChange(currentPage) {
		this.queryParams.pageNum = currentPage;
		 getlistMembershipStyle(this.queryParams).then(res => {
				this.tableData = res.rows;
				this.total = res.total;
		 })
	}
  },

};
</script>
<style scoped lang="less">
  .container {
		width: 100%;
		min-height: 100vh;
    .container-shell {
      height: calc(100% - 0);
      overflow: scroll;
    }
    .sub-banner{
      width: 100%;
      height: 248px;
    }
    .right-shell{
      width: 1420px;
      min-height: 560px;
      padding-left: 300px;
      position: relative;
      margin: 0 auto;
      & .content-shell{
        width: 100%;
        height: 100%;
    		.content {
    			width: 100%;
    			height: calc(100% - 65px);
					overflow-x: hidden;
    			overflow-y: scroll;

          .img-text{
            position: absolute;
            top: 200px;
            left: 430px;
            color: #CC3333;
            font-weight: 700;
          }
          /deep/ .el-input__inner{
            background: transparent;
            color: #fff;
            border-color: #CC3333
          }
          /deep/ .el-select__caret{
            color: #CC3333;
          }

          /deep/ .el-table thead tr th.el-table__cell{
            background: #CC3333;
            color: #fff;
            font-size: 18px;
          }
          /deep/ .el-pagination{
            &.is-background .btn-next, &.is-background .btn-prev, &.is-background .el-pager li {
              background-color: transparent;
              border: 1px solid #DDD;
              font-weight: 400;
            }
            &.is-background .el-pager li:not(.disabled).active{
              background-color: #CC3333;
              border: none;
            }
          }
          /deep/ .el-button--text{
            color: #CC3333;
          }
          /deep/ .el-input{
            width: 210px;
            height: 33px;
            & .el-input__inner{
              height: 100%;
            }
          }

    		}
      }
    }
  }

</style>
