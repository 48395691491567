<template>
  <div class="header-container">
    <div class="header-logo">
      <img src="~@/assets/img/logo.png" style="width: 348px;" alt="" />
    </div>
    <div class="header-shell flex-end">
      <div class="header-navbar flex-end">
        <div
          v-for="item in navList"
          :key="item.id"
          class="nav-item height-fill flex-center"
          :class="activeId === item.id && 'active'"
          @click="navClick(item)"
        >
          <span>{{ item.name }}</span>
          <div
            class="drawer-shell"
            v-if="item.children && item.children.length > 0"
          >
            <div
              class="nav-subitem"
              v-for="subItem in item.children"
              :key="subItem.id"
              @click="subNavClick(item, subItem)"
            >
              {{ subItem.name }}
            </div>
          </div>
        </div>
          <div class="join-us"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { StorageUtil } from '/utils/util.js'
import navList from '/utils/navList';
export default {
  data() {
    return {
      navList,
      menu: {},
      activeId: '',
    }
  },
  props: {
    // intervalUpdate: {    //定义onOK属性
    //     type: Function
    // }
  },
  mounted() {
    // StorageUtil.set('currentRouter', this.navList[0])
    this.menu = StorageUtil.get('currentRouter')
    this.$nextTick(() => {
      this.activeId = this.menu && this.menu.id
    })
  },
  methods: {
    navClick(item) {
      StorageUtil.set('currentRouter', item)
      if(item.id === 8) {
        window.open(item.url);
        return;
      }
      if (item.children) {
        StorageUtil.set('currentPage', item.children[0])
        if ('/' + this.$route.name == item.children[0].url) {
          this.$emit('navClick')
        } else {
          this.$emit('navClick')
          this.$router.replace({
            path: item.children[0].url,
            query: {
              id: item.id,
            },
          })
        }
      } else {
        this.$emit('navClick')
        this.$router.replace({
          path: item.url,
          query: {
            id: item.id,
          },
        })
      }
    },
    subNavClick(item, subItem) {
      // debugger
      StorageUtil.set('currentRouter', item)
      StorageUtil.set('currentPage', subItem)
      this.$nextTick(()=> {
        this.$emit('navClick')
        this.$router.replace({
          path: subItem.url,
        })
      })


    }
  },
}
</script>
<style scoped lang="less">
.header-container{
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, .9);
}
.header-shell {
  width: 1420px;
  margin: 0 auto;
  height: 100px;
  line-height: 100px;
  color: #212121;
}
.header-logo {
  width: 350px;
  height: 100px;
  padding-top: 20px;
  //padding-left: 50px;
  position: absolute;
  left: 50px;
}
.header-navbar {
  width: calc(100% - 110px);
  height: 100%;
  position: relative;
  .join-us{
    position: absolute;
    width: 50px;
    height: 34px;
    background: url("@/assets/img/joinUs.gif")no-repeat center center;
    right: -40px;
    top: 25px;
    background-size: 100% auto;
  }
}
.nav-item {
  padding: 0 20px;
  background: transparent;
  cursor: pointer;
  color: #cc3333;
  position: relative;
}
.nav-item:hover,
.nav-item.active {
  background: #cc3333;
  color: #fff;
}
.nav-item:hover .drawer-shell {
  display: block;
}
.drawer-shell {
  position: absolute;
  width: 100%;
  min-height: 38px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  background: #cc3333;
  top: 100px;
  left: 0;
  display: none;
  z-index: 999;
  & .nav-subitem {
    padding: 6px 0;
    &:hover {
      background: #fff;
      color: #cc3333;
    }
  }
}
</style>
