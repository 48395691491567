<template>
  <div class="container" >
    <!-- header -->
    <div>
      <header-top></header-top>
    </div>
    <div class="container-shell">
      <div class="carousel-shell">
        <el-carousel :interval="5000" arrow="never" :height="'100%'">
          <el-carousel-item v-for="item in bannerList" :key="item">
            <img :src="imgBaseUrl.imgBaseUrl + item" class="width-fill height-fill" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content-shell">
        <div class="list-shell flex">
          <div class="flex-treble padding-right-xl">
            <div class="news-list">
              <div class="news-title flex justify-between">
                <div><i class="el-icon-s-goods text-xxl margin-right-sm"></i><span class="text-bold">工作动态</span></div>
                <span class="text-df text-gray" @click="tosysList(2, 0)">查看更多</span>
              </div>
            </div>
            <div class="news-item-shell">
              <div v-for="item in newsList" class="flex news-item" :key="item.id" @click="tosysDetail(2, 0, `/listAssociationDetail/${item.id}`)">
                <div class="item-image flex-twice">
                  <img :src="imgBaseUrl.imgBaseUrl + item.photo" alt="" class="width-fill height-fill">
                </div>
                <div class="flex-treble news-text">
                  <div class="ellipsis text-bold">{{ item.title }}</div>
                  <div class="news-desc ellipsis3 text-gray">{{ item.remark }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-treble padding-right-xl">
            <div class="news-list">
              <div class="news-title flex justify-between">
                <div><i class="el-icon-s-data text-xxl margin-right-sm"></i><span class="text-bold">试点分布</span></div>
                <span class="text-df text-gray"  @click="tosysList(3, 2)">查看更多</span>
              </div>
            </div>
            <div class="news-item-shell map-shell padding-top-xl"  @click="tosysList(3, 2)">
              <img src="~@/assets/img/map2.png" class="width-fill height-fill margin-top-lg" alt="">
            </div>
          </div>
          <div class="flex-treble">
            <div class="news-list">
              <div class="news-title flex justify-between">
                <div><i class="el-icon-s-finance text-xxl margin-right-sm"></i><span class="text-bold">通知公告</span></div>
                <span class="text-df text-gray" @click="tosysList(2, 2)">查看更多</span>
              </div>
            </div>
            <div class="news-item-shell padding-top-sm">
              <div v-for="item in newsList2" class="width-fill" :key="item.id"  @click="tosysDetail(2, 2, `/listNoticeDetail/${item.id}`)">
                <div class="width-fill flex-between">
                  <div class="ellipsis news-text1 padding-tb-xs">{{ item.title }}</div>
                  <div>{{ item.timee }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-shell flex" style="height: 520px;">
          <div class="flex-sub">
            <div class="news-list">
              <div class="news-title flex justify-between">
                <div class="flex">
                  <i class="el-icon-user-solid text-xxl margin-right-sm"></i><span class="text-bold margin-right-xl">会员风采</span>
                  <div class="tab-shell text-sm">
                    <span :class="tab === 0 && 'active'" @click="toTab(0)">企业</span><span :class="tab === 1 && 'active'" @click="toTab(1)">院校</span>
                  </div>
                </div>
                <span class="text-df text-gray" @click="tosysList(5, 0)">查看更多</span>
              </div>
            </div>
            <div class="news-item-shell hy-shell flex-wrap">
              <div class="hy-image-shell" v-for="(item, i) in newsList6" :key="item" v-if="i < 4" @click="tosysDetail(5, 0, `/listMembershipStyleDetail/${item.id}`)">
                <!-- <div class=""> -->
                  <img :src="imgBaseUrl.imgBaseUrl +item.photo" alt="" class="width-fill height-fill">
                  <div class="hy-title">{{ item.title }}</div>
                <!-- </div> -->
              </div>
            </div>
            <div class="margin-top hy-banner-shell"  @click="tosysList(4, 0)">
              <img src="~@/assets/img/dj.png" alt="" class="width-fill height-fill">
            </div>

          </div>
        </div>
        <div class="list-shell flex" style="height: 280px">
          <div class="flex-sub margin-right-xl">
            <div class="news-list">
              <div class="news-title flex justify-between">
                <div class="flex">
                  <i class="el-icon-s-management text-xxl margin-right-sm"></i><span class="text-bold margin-right-xl">政策法规</span>

                </div>
                <span class="text-df text-gray"  @click="tosysList(3, 0)">查看更多</span>
              </div>
            </div>
            <div class="notice-item-shell padding-top-sm">
              <div v-for="item in newsList3" class="width-fill" :key="item.id" @click="tosysDetail(3, 0, `/listPolicyStatuteDetail/${item.id}`)">
                <div class="width-fill flex-between">
                  <div class="ellipsis news-text2 padding-tb-xs">{{ item.title }}</div>
                  <div>{{ item.timee }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-twice">
            <div class="news-list">
              <div class="news-title flex justify-between">
                <div class="flex">
                  <i class="el-icon-s-order text-xxl margin-right-sm"></i><span class="text-bold margin-right-xl">需求信息</span>

                </div>
                <span class="text-df text-gray" @click="tosysList(6, 1)">查看更多</span>
              </div>
            </div>
            <div class="padding-left-xl flex padding-top demand-shell">
              <div class="flex-sub padding-left-xs">
                <div class="text-bold demand-title padding-right-xl margin-bottom-xs margin-right-xl">企业需求</div>
                <div v-for="item in newsList4" :key="item" class="flex align-center padding-tb-xs">
                  <i class="item-dot-red margin-right-xl"></i>
                  <div class="flex-treble flex"><span class="flex-sub">{{item.positionName}}</span><span class="flex-sub">{{item.address}}</span><span class="flex-sub">{{item.companyName}}</span></div>
<!--                  <span class="flex-sub"></span>-->
                </div>
              </div>
              <div class="flex-sub  padding-left-xs">
                <div class="text-bold demand-title padding-right-xl margin-bottom-xs margin-right-xl">院校需求</div>
                <div v-for="item in newsList5" :key="item" class="align-center padding-tb-xs flex">
                  <i class="item-dot-red margin-right-xl"></i>
                  <div class="ellipsis" style="width: 400px">{{item.title}}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <bottom-footer/>
    </div>

  </div>
</template>
<script>

import HeaderTop from "@/components/parts/headerTop";
import BottomFooter from "@/components/parts/bottomFooter";
import imgBaseUrl from '/utils/imgBaseUrl';
import navList from '/utils/navList';
import { getlistMembershipStyle,getlistCompany,getlistAcademy,getListAssociation,getListBanner,getlistNotice,getlistPolicyStatute } from "@/api/api.js";
export default {
  name: "index",
  data() {
    return {
      imgBaseUrl,
      navList,
	  bannerList:[],
      clintWidth: 1920,
      clintHeight: 1080,
      newsList: [
      ],
      newsList2:[
      ],
	  newsList3:[
	  ],
	  newsList4:[
	  ],
	  newsList5:[
	  ],
	  newsList6:[
	  ],
      tab: 0
    }
  },
  components: {
    "header-top": HeaderTop,
    "bottom-footer": BottomFooter,
  },
  mounted() {
    // this.resize();
    this.getList();
	this.getListBanner();
	this.getlistNotice();
	this.getlistPolicyStatute();
	this.getlistCompanyTable();
	this.getlistAcademyTable();
    this.getlistMembershipStyle();
  },

  methods: {
	  toTab(e){
		  getlistMembershipStyle({type:e}).then(res => {
			this.newsList6 = res.rows;
		  })
		  this.tab = e;
	  },
    tosysList(index, idx){
      let list = this.navList;
      // debugger
      StorageUtil.set('currentRouter', list[index])
      list[index].children && StorageUtil.set('currentPage', list[index].children[idx])
      this.$router.replace({
        path: list[index].children[idx].url,
      })
    },
    tosysDetail(index, idx, url){
      let list = this.navList;
      StorageUtil.set('currentRouter', list[index])
      list[index].children && StorageUtil.set('currentPage', list[index].children[idx])
      this.$router.replace({
        path: url,
      })
    },
	  getlistMembershipStyle(){
	    getlistMembershipStyle({type:0}).then(res => {
	  	this.newsList6 = res.rows;
	    })
	  },
	  getlistAcademyTable(){
	    getlistAcademy().then(res => {
	  	this.newsList5 = res.rows;
	    })
	  },
	  getlistCompanyTable(){
	    getlistCompany().then(res => {
	  	this.newsList4 = res.rows;
	    })
	  },
    getList(){
      getListAssociation().then(res => {
       this.newsList = res.rows;
      })
    },
	getListBanner(){
	  getListBanner().then(res => {
		this.bannerList = res.rows;
	  })
	},
	getlistNotice(){
	  getlistNotice().then(res => {
		this.newsList2 = res.rows;
	  })
	},
	getlistPolicyStatute(){
	  getlistPolicyStatute().then(res => {
		this.newsList3 = res.rows;
	  })
	}
  },
  destroyed() {

  }
};
</script>
<style scoped lang="less">
  .container{
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
  }
  .container-shell{
    height: 100%;
    overflow: scroll;
  }
  .carousel-shell, .el-carousel{
    height: 100vh;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .content-shell{
    width: 1420px;
    margin: 0 auto;

  }
  .list-shell{
    min-height: 300px;
    margin-top: 22px;
    height: 415px;
    overflow: hidden;
    padding: 10px;
  }
  .news-title{
    color: #CC3333;
    border-bottom: 1px solid #CC3333;
    .text-df.text-gray {
      cursor: pointer;
    }
  }
  .news-item-shell{
    min-height: 200px;
    overflow: hidden;
  }
  .notice-item-shell{
    height: 250px;
    overflow: hidden;
    cursor: pointer;
  }
  .news-item{
    border-bottom: 1px solid #aaaaaa;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
  }
  .news-item:last-child{
    border-bottom: none;
  }
  .item-image{
    width: 184px;
    height: 106px;
    overflow: hidden;
    margin-right: 20px
  }
  .news-text{
    width: 250px;
  }
  .news-text1{
    width: 300px;
  }
  .news-text2{
    width: 360px;
  }
  .news-desc{
    height: 70px;
    text-indent: 2em;
    margin-top: 4px;
    text-align: justify;
  }
  .tab-shell {
    position: relative;
    top: -2px;
  }
  .tab-shell span{
    padding: 4px 16px;
    border: 1px solid #CC3333;
    background: #fff;
    cursor: pointer;
  }
  .tab-shell span.active{
    background: #CC3333;
    color: #fff;
  }
  .tab-shell span:first-child{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .tab-shell span:last-child{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .hy-shell{
    width: calc(101%);
    overflow: hidden;
  }
  .hy-image-shell{
    width: 24%;
    height: 230px;
    position: relative;
    margin-top: 18px;
    cursor: pointer;
    margin-right: 1%;
  }
  .hy-title{
    text-align: center;
    color: #fff;
    line-height: 34px;
    background: #CC3333;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .hy-banner-shell{
    height: 220px;
    cursor: pointer;
  }
  .demand-shell{
    height: 250px;
    overflow: hidden;
  }
  .demand-title {
    color: #CC3333;
  }
  .item-dot-red{
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #CC3333;
  }
</style>
