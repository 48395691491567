<template>
    <div class="container">
        <!-- header :style="{ width: clintWidth + 'px', height: clintHeight + 'px' }" -->
        <div>
            <HeaderTop @navClick="$refs.MenuListRef.init();$refs.BreadCrumbRef.init()"/>

        </div>
        <div class="container-shell">
            <div class="sub-banner"><img src="~@/assets/img/subBanner6.png" alt=""
                                             class="width-fill height-fill"></div>
            <div class="right-shell">
                <MenuList ref="MenuListRef" @menuClick="$refs.BreadCrumbRef.init()"/>
                <div class="content-shell">
                    <BreadCrumb ref="BreadCrumbRef"/>
                    <div class="content margin-bottom-xl">
                        <div class="flex-between margin-top-xl">
                            <div class="flex-start flex-sub">
                                <span class="flex-sub">企业名称：</span>
                                <el-input class="flex-treble" v-model="form.companyName" placeholder="请输入内容"></el-input>
                            </div>
                            <div class="flex-start flex-sub">
                                <span class="flex-sub">岗位名称：</span>
                                <el-input class="flex-treble" v-model="form.positionName" placeholder="请输入内容"></el-input>
                            </div>
                            <div class="flex-start flex-sub">
                                <span class="flex-sub">工作类型：</span>
                                <el-input class="flex-treble" v-model="form.typeName" placeholder="请输入内容"></el-input>
                            </div>
                        </div>
                        <div class="flex-between margin-top-xl">
                            <div class="flex-start flex-sub">
                                <span class="flex-sub">工作地点：</span>
                                <el-input class="flex-treble" v-model="form.address" placeholder="请输入内容"></el-input>
                            </div>
                            <div class="flex-start flex-sub">
                                <span class="flex-sub">学历要求：</span>
                                <el-input class="flex-treble" v-model="form.require" placeholder="请输入内容"></el-input>
                            </div>
                            <div class="flex-start flex-sub">
                                <span class="flex-sub">招聘人数：</span>
                                <el-input class="flex-treble" v-model="form.num" placeholder="请输入内容"></el-input>
                            </div>
                        </div>
                        <div class="flex-center-top margin-top-xl">
                            <span class="flex-sub">工作职责：</span>
                            <el-input class="text-area-style" :rows="6" type="textarea" v-model="form.duty" placeholder="请输入内容"></el-input>
                        </div>
                        <div class="flex-center-top margin-top-xl">
                            <span class="flex-sub">岗位要求：</span>
                            <el-input class="text-area-style" :rows="6" type="textarea" v-model="form.ability" placeholder="请输入内容"></el-input>
                        </div>
                        <div class="flex-center-top margin-top-xl">
                            <span class="flex-sub">投递方式：</span>
                            <el-input class="text-area-style" :rows="6" type="textarea" v-model="form.deliver" placeholder="请输入内容"></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <BottomFooter/>
        </div>

    </div>
</template>
<script>
import HeaderTop from "@/components/parts/headerTop";
import BottomFooter from "@/components/parts/bottomFooter";
import MenuList from "@/components/parts/menuList";
import BreadCrumb from "@/components/parts/BreadCrumb";
 import {getCompany} from '@/api/api'

export default {
    name: "listCompanyDetail",
    data() {
        return {
            clintWidth: 1920,
            clintHeight: 1080,
            form: {},

            tab: 0
        }
    },
    components: {
        HeaderTop,
        BottomFooter,
        MenuList,
        BreadCrumb
    },
    mounted() {
        // this.resize();
        window.scrollTo(0, 0);

    },
    created() {
        const id = this.$route.query.id;
        this.getCompany(id);
    },
    methods: {

        getCompany(id) {
            getCompany(id).then(res => {
                this.form = res.data;
            })
        },
    },
    destroyed() {

    }
};
</script>
<style scoped lang="less">
.container {
    width: 100%;
    min-height: 100vh;

    .container-shell {
        height: calc(100% - 0);
        overflow-y: scroll;
    }

    .sub-banner {
        width: 100%;
        height: 248px;
    }

    .right-shell {
        width: 1420px;
        min-height: 100%;
        padding-left: 300px;
        position: relative;
        margin: 0 auto;

        & .content-shell {
            width: 100%;
            height: 100%;

            .content {
                width: 100%;
                // height: calc(100% - 65px);
                // overflow-x: hidden;
                /deep/ .el-input__inner{
                    background: transparent;
                    //color: #fff;
                    //border-color: #CC3333;
                    width: 240px;
                    height: 33px;
                }
                .text-area-style{
                    flex: 11;
                    width: 1034px;
                }
                /deep/ .el-textarea__inner{
                }

            }
        }
    }
}

</style>
