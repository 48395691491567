import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import storageUtil from '@/utils/storageUtil.js'

Vue.config.productionTip = false

import { Carousel,CarouselItem,Select, Option, Breadcrumb, BreadcrumbItem, Table, TableColumn, Button, Pagination, Input } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Loading } from 'element-ui';
Vue.use(Loading.directive);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
Vue.use(Pagination);
Vue.use(Input);

import '@/assets/main.css'
// Vue.prototype.$storageUtil = storageUtil;
// 引入相关css
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
