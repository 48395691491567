import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

// import api from "@/api/api"
// import {
// 	ACCESS_TOKEN,
// 	USER_NAME,
// 	USER_INFO,
// 	TENANTID
// } from "@/utils/constants"


export default new Vuex.Store({
	state: {
		// token: uni.getStorageSync(ACCESS_TOKEN).username || '',
		// userid: uni.getStorageSync(USER_INFO).username || '',
		// username: uni.getStorageSync(USER_INFO).username || '',
		// realname: uni.getStorageSync(USER_INFO).realname || '',
		// welcome: uni.getStorageSync(USER_INFO).welcome || '',
		// avatar: uni.getStorageSync(USER_INFO).avatar || '',
		// page: null
    menuList: [],
    breadcrumb: [],
    currentRouter: {}
	},
	mutations: {
		SET_ROUTER(state, list) {
			state.menuList = list
		},
    SET_BREAD(state, list) {
      state.breadcrumb = list
    },
    GET_ROUTER(state) {
      return state.menuList
    },
    GET_BREAD(state) {
      return state.breadcrumb
    },
    SET_CURRENTROUTER(state, object) {
      state.currentRouter = object
			console.log('currentRouter', state.currentRouter)
    },
    GET_CURRENTROUTER(state) {
			console.log('currentRouter', state.currentRouter)
      return state.currentRouter
    }
		
	},
	actions: {
		setRouter(list){
      store.mutations.SET_ROUTER(list)
      
    },
    getRouter(){
      return store.mutations.GET_ROUTER()
    },
    setBread(list){
      store.mutations.SET_BREAD(list)
    },
    getBread(){
      return store.mutations.GET_BREAD()
    },
    setCurrentRouter(object){
      store.mutations.SET_CURRENTROUTER(object)
      console.log('state current router', state.currentRouter)
    },
    getCurrentRouter(){
      return store.mutations.GET_CURRENTROUTER()
    },

		// //手机号登录
		// PhoneLogin(userInfo) {
		// 	return new Promise((resolve, reject) => {
		// 		// api.phoneNoLogin(userInfo).then(response => {
		// 		api.phoneLoginByOpenID(userInfo).then(response => {
		// 			if (response.code == 200) {
		// 				const result = response.result
		// 				const userInfo = result.userInfo
		// 				uni.setStorageSync(ACCESS_TOKEN, result.token);
		// 				uni.setStorageSync(USER_INFO, userInfo);
		// 				store.mutations.SET_TOKEN(result.token)
		// 				store.mutations.SET_AVATAR(userInfo.avatar)
		// 				store.mutations.SET_NAME({
		// 					username: userInfo.username,
		// 					realname: userInfo.realname
		// 				})
		// 				resolve(response)
		// 			} else {
		// 				reject(response)
		// 			}
		// 		}).catch(error => {
		// 			reject(error)
		// 		})
		// 	})
		// },
	},
	// getters: {
	// 	token: state => state.token,
	// 	username: state => {
	// 		state.userid = uni.getStorageSync(USER_INFO).username;
	// 		return state.username
	// 	},
	// 	nickname: state => {
	// 		state.userid = uni.getStorageSync(USER_INFO).realname;
	// 		return state.user.realname
	// 	},
	// 	avatar: state => {
	// 		state.userid = uni.getStorageSync(USER_INFO).avatar;
	// 		return state.user.avatar
	// 	},
	// 	userid: state => {
	// 		state.userid = uni.getStorageSync(USER_INFO).id;
	// 		return state.userid
	// 	},
	// }
})