
<template>
  <div class="menu-shell">
      <div class="menu-top">
        <div class="top-text">快捷导航</div>
        <div class="top-text1">Quick navigation</div>
      </div>
      <div class="menu-list">
        <div class="menu-item flex-between" v-for="(item, index) in menus" :key="index" :class="activeId === item.id && 'active'" @click="menuClick(item, index)">
          <span>{{ item.name }}</span>
          <span class="item-arrow">></span>
        </div>
      </div>
  </div>
</template>
<script>
import {StorageUtil} from '/utils/util.js'
export default {
  name: "menuList",
	// props: {
	// 	active: {
	// 		type: Number,
	// 		required: false,
	// 		default: () => 0
	// 	}
	// },
  data(){
      return{
        menus: [],
        currentMenu: {},
        activeId: ''
      }
  },
  props: {
      // intervalUpdate: {    //定义onOK属性
      //     type: Function
      // }
  },
  mounted(){
    // let router = 
    this.init()
  },
  methods:{
    init(){
      this.currentMenu = StorageUtil.get('currentRouter')
      let page = StorageUtil.get('currentPage')
      // debugger
      this.$nextTick(() => {
        this.menus = this.currentMenu.children || [this.currentMenu]
        if (page) {
          this.activeId = page.id
          let i = this.currentMenu.children.findIndex(menu => menu.id == page.id)
          this.menus[i].show = true;
        } else {
          this.activeId = this.menus[0].id;
          this.menus[0].show = true;
        }
        
      })
    },
    menuClick(item, index){
      // debugger
      this.currentMenu.children.forEach((child, idx) => {
        if (idx === index) {
          child.show= true;
        } else {
          child.show = false;
        }
        
      });
      // this.currentMenu.children[index].show= true;
      this.activeId = item.id;
      StorageUtil.set('currentRouter', this.currentMenu)
      StorageUtil.set('currentPage', item)
      this.$router.replace({
            path: item.url,
          })
      this.$emit('menuClick')

    }
  }
}
</script>
<style scoped lang="less"> 
  .menu-shell{
    width: 220px;
    min-height: 100px;
    position: absolute;
    top: -60px;
    left: 0;
    z-index: 999;
    .menu-top{
      width: 100%;
      height: 125px;
      background: #CC3333;
      color: #fff;
      padding: 25px 30px;
      margin-bottom: 15px;
      & .top-text{
        font-size: 24px;
      }
      & .top-text1{
        font-size: 18px;
        opacity: .2;
        
      }
      &:after{
        content: "";
        display: block;
        width: 25px;
        height: 4px;
        background: #fff;
        margin-top: 10px;
      }
    }
    .menu-list {
      .menu-item {
        padding: 10px 30px;
        font-size: 16px;
        color: #666;
        border-bottom: 1px solid #eee;

        cursor: pointer;
        & .item-arrow{
          color: #CC3333;
        }
        &:last-child {
          border: none;
        }
        &.active, &:hover{
          background: #CC3333;
          color: #fff;
          & .item-arrow{
          color: #fff;
        }
        }
      }
    }
  }
  
</style>
