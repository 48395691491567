
<template>
    <div class="footer-shell flex-between">
        <div class="footer-content">
            <div class="flex justify-center margin-tb-lg align-center">
                <span class="text-lg text-bold margin-right-xl">友情链接</span>
                <div class="margin-right-xl">
                    <el-select placeholder="请选择政府机构单位" class="select-custom" @change="toWebsite">
                        <el-option
                        v-for="item in options1"
                        :key="item.url"
                        :label="item.name"
                        :value="item.url">
                        </el-option>
                    </el-select>


                </div>
				<div class=" margin-right-xl">
				    <el-select placeholder="请选择企业单位" class="select-custom" @change="toWebsite">
				        <el-option
				        v-for="item in options2"
				        :key="item.url"
				        :label="item.name"
				        :value="item.url">
				        </el-option>
				    </el-select>
				</div>
                <div class=" margin-right-xl">
                    <el-select placeholder="请选择院校单位" class="select-custom" @change="toWebsite">
                        <el-option
                        v-for="item in options3"
                        :key="item.url"
                        :label="item.name"
                        :value="item.url">
                        </el-option>
                    </el-select>
                </div>
             
            </div>
            <div class="flex justify-center margin-bottom-lg"><span class="margin-right-sm">地址：山东省济南市历下区泺源大街66号银座3期c座15楼1505室</span>   <span class="margin-right-sm">邮编：250000</span>    <span>电话：0531-83601959</span>   </div>
            <div class="text-center">山东省产教融合促进会版权所有   <span class="ban" style=" margin-left: 15px;cursor:pointer;" @click="toWebsiteBan">鲁ICP备2024070370号</span></div>
            <div class="QR-code-shell flex">
                <div class="code-item text-center flex-vertical">
                    <div class="img-shell">
                        <img src="~@/assets/img/code2.png" class="width-fill height-fill" alt="">
                    </div>
                    <div class="text-sm margin-top-sm">视频号二维码</div>
                </div>
               <div class="code-item text-center flex-vertical">
                    <div class="img-shell">
                        <img src="~@/assets/img/code1.png" class="width-fill height-fill" alt="">
                    </div>
                    <div class="text-sm margin-top-sm">公众号二维码</div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { getListCollurl,getlistCopurl,getlistGovurl } from '@/api/api'
export default {
    data(){
        return{
            options1: [{
                value: 'http://www.jinan.gov.cn/',
                label: '济南市人民政府'
                },{
                value: 'http://www.lixia.gov.cn//',
                label: '历下区人民政府'
                }
              ],
              options2: [{
                  value: 'http://www.lushang.com.cn/',
                  label: '鲁商集团'
              }],
              options3: [{
                  value: 'https://www.sdu.edu.cn/',
                  label: '山东大学'
              }],

        }
    },
    props: {
        // intervalUpdate: {    //定义onOK属性
        //     type: Function
        // }
    },
    mounted(){

    },
	created(){
		  this.getListCollurl();
		  this.getlistCopurl();
		  this.getlistGovurl();
	},
    methods:{
		toWebsiteBan(){
		    window.open("https://beian.miit.gov.cn/#/Integrated/index")
		},
		getListCollurl(){
		  getListCollurl().then(res => {
			this.options3 = res.rows;
		  })
		},
		getlistCopurl(){
		  getlistCopurl().then(res => {
			this.options2 = res.rows;
		  })
		},
		getlistGovurl(){
		  getlistGovurl().then(res => {
			this.options1 = res.rows;
		  })
		},
        toWebsite(e){
           if(e) {
            window.open(e)
           }
        }
    }
}
</script>
<style scoped>
    .footer-shell{
        width: 100%;
        height: 200px;
        color: #fff;
        background: #CC3333;
    }
    .footer-content{
        width: 1420px;
        height: 100%;
        margin: 0 auto;
        position: relative;
    }
    /deep/ .el-input__inner{
        background: transparent;
        color: #fff;
        border-color: #fff
    }
    .QR-code-shell{
        position: absolute;
        right: 0;
        top: 30px;
    }
    .img-shell{
        width: 82px;
        height: 82px;
    }
    .code-item{
        width: 100px;
    }
	.ban:hover {
	  color: #288AD0; 
	}
</style>
