<template>
  <div class="container">
    <!-- header  :style="{ width: clintWidth + 'px', height: clintHeight + 'px' }" -->
    <div>
      <HeaderTop @navClick="$refs.MenuListRef.init()"/>

    </div>
    <div class="container-shell">
      <div class="sub-banner">
        <img src="~@/assets/img/subBanner1.png" alt="" class="width-fill height-fill">
      </div>
      <div class="right-shell">
        <MenuList ref="MenuListRef" @menuClick="$refs.BreadCrumbRef.init()"/>
        <div class="content-shell">
          <BreadCrumb ref="BreadCrumbRef"/>
					<div class="padding-top-xl content">
						<div class="text-content ql-editor" v-html="this.content"></div>
					</div>
        </div>
      </div>
      <BottomFooter />
    </div>

  </div>
</template>
<script>
import HeaderTop from "@/components/parts/headerTop";
import BottomFooter from "@/components/parts/bottomFooter";
import MenuList from "@/components/parts/menuList";
import BreadCrumb from "@/components/parts/BreadCrumb";
// import DynamicMarquee from "vue-dynamic-marquee";
import { getInfo} from '@/api/api'
export default {
  name: "sysList",
  data() {
    return {
	  content:"",
      clintWidth: 1920,
      clintHeight: 1080,
      newsList: [
      ],
      subbannerSrc:'',

      tab: 0
    }
  },
  components: {
    HeaderTop,
    BottomFooter,
    MenuList,
    BreadCrumb
  },
  mounted() {
    this.menu = StorageUtil.get('currentRouter')
    this.$nextTick(() => {
      this.subbannerSrc = `/assets/img/subBanner${this.menu.id}.png`
    })
  },
  created(){
	  this.getInfo();
  },

  methods: {
	  getInfo(){
	    getInfo().then(res => {
	  	this.content = res.data.content;
	    })
	  },

  },
  destroyed() {

  }
};
</script>
<style scoped lang="less">
  .container {
		width: 100%;
		min-height: 100vh;
    .container-shell {
      height: calc(100% - 0);
			 overflow: scroll;
    }
    .sub-banner{
      width: 100%;
      height: 248px;
    }
    .right-shell{
      width: 1420px;
      min-height: 560px;
      padding-left: 300px;
      position: relative;
      margin: 0 auto;
      & .content-shell{
        width: 100%;
        height: 100%;
				.content {
					width: 100%;
					// height: calc(100% - 65px);
					// overflow: scroll;
				}
      }
    }
  }

</style>
