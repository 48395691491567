// import http from '@/utils/http.js'
import request from "/utils/request.js";

// 获取路由
export const getListAssociation = () => {
  return request({
    url: '/business/website/listAssociation',
    method: 'get'
  })
  // return http.get('/business/website/listAssociation')
}
// 首页--轮播图
export const getListBanner = () => {
  return request({
    url: '/business/website/banner',
    method: 'get'
  })
}
// 首页--院校
export const getListCollurl = () => {
  return request({
    url: '/business/website/listCollurl',
    method: 'get'
  })
}
// 首页--企业
export const getlistCopurl = () => {
  return request({
    url: '/business/website/listCopurl',
    method: 'get'
  })
}
// 首页--政府
export const getlistGovurl = () => {
  return request({
    url: '/business/website/listGovurl',
    method: 'get'
  })
}
// 促进会概况---获取促进会简介详细信息
export const getInfo = () => {
  return request({
    url: '/business/website/getInfo',
    method: 'get'
  })
}

// 促进会概况---获取机构设置详细信息
export const getInstitution = () => {
  return request({
    url: '/business/website/Institution',
    method: 'get'
  })
}
// 促进会概况---获取促进会章程详细信息
export const getConstitution = () => {
  return request({
    url: '/business/website/Constitution',
    method: 'get'
  })
}
//  促进会概况--查询专家委员会列表
export const getlistExpert = (query) => {
  return request({
    url: '/business/website/listExpert',
    method: 'get',
	params: query
  })
}
//  促进会概况--获取专业委员会详细信息
export const getSpecialization = () => {
  return request({
    url: '/business/website/Specialization',
    method: 'get'
  })
}
//  促进会概况--获取联系我们详细信息
export const getAddress = () => {
  return request({
    url: '/business/website/Address',
    method: 'get'
  })
}
//  动态资讯---查询促进会动态列表
export const getlistAssociation = (query) => {
  return request({
    url: '/business/website/listAssociation',
    method: 'get',
	params: query
  })
}
//  动态资讯---获取促进会动态详细信息
export const getAssociation = (id) => {
  return request({
    url: '/business/website/Association/'+id,
    method: 'get'
  })
}
//  动态资讯--查询产教动态列表
export const getlistIndedu = (query) => {
  return request({
    url: '/business/website/listIndedu',
    method: 'get',
	params: query
  })
}
//  动态资讯--获取产教动态详细信息
export const getIndedu = (id) => {
  return request({
    url: '/business/website/Indedu/'+id,
    method: 'get'
  })
}
//  动态资讯--查询通知公告列表
export const getlistNotice = (query) => {
  return request({
    url: '/business/website/listNotice',
    method: 'get',
	params: query
  })
}
//  动态资讯--获取通知公告详细信息
export const getNotice = (id) => {
  return request({
    url: '/business/website/Notice/'+id,
    method: 'get'
  })
}
//  政策咨询--查询政策法规列表
export const getlistPolicyStatute = (query) => {
  return request({
    url: '/business/website/listPolicyStatute',
    method: 'get',
	params: query
  })
}
//  政策咨询--获取政策法规详细信息
export const getPolicyStatute = (id) => {
  return request({
    url: '/business/website/PolicyStatute/'+id,
    method: 'get'
  })
}
//  政策咨询--查询专题报道列表
export const getlistSpecial = (query) => {
  return request({
    url: '/business/website/listSpecial',
    method: 'get',
	params: query
  })
}
//  政策咨询--获取专题报道详细信息息
export const getSpecial = (id) => {
  return request({
    url: '/business/website/Special/'+id,
    method: 'get'
  })
}
//  政策咨询--查询试点分布列表
export const getlistExperimental = (query) => {
  return request({
    url: '/business/website/listExperimental',
    method: 'get',
	params: query
  })
}
//  政策咨询--查询试点分布列表
export const getlistExperimentalNum = (query) => {
  return request({
    url: '/business/website/listExperimentalNum',
    method: 'get',
	params: query
  })
}
//  党建专题--查询党建工作列表
export const getlistBuilding = (query) => {
  return request({
    url: '/business/website/listBuilding',
    method: 'get',
	params: query
  })
}
//  党建专题--获取党建工作详细信息
export const getBuilding = (id) => {
  return request({
    url: '/business/website/Building/'+id,
    method: 'get'
  })
}
// 会员--查询会员风采列表
export const getlistMembershipStyle = (query) => {
  return request({
    url: '/business/website/listMembershipStyle',
    method: 'get',
	params: query
  })
}
//   会员--获取会员风采详细信息
export const getMembershipStyle = (id) => {
  return request({
    url: '/business/website/MembershipStyle/'+id,
    method: 'get'
  })
}
// 需求对接--查询行业需求列表
export const getlistIndustry = (query) => {
  return request({
    url: '/business/website/listIndustry',
    method: 'get',
	params: query
  })
}
//  需求对接--获取行业需求详细信息
export const getIndustry = (id) => {
  return request({
    url: '/business/website/Industry/'+id,
    method: 'get'
  })
}
// 需求对接--查询企业需求列表
export const getlistCompany = (query) => {
  return request({
    url: '/business/website/listCompany',
    method: 'get',
	params: query
  })
}
//  需求对接--获取企业需求详细信息
export const getCompany = (id) => {
  return request({
    url: '/business/website/Company/'+id,
    method: 'get'
  })
}

//  需求对接--查询院校需求列表
export const getlistAcademy = (query) => {
  return request({
    url: '/business/website/listAcademy',
    method: 'get',
	params: query
  })
}
//  需求对接--获取院校需求详细信息
export const getAcademy = (id) => {
  return request({
    url: '/business/website/Academy/'+id,
    method: 'get'
  })
}
//  需求对接--查询院校需求列表
export const getlistDownload = (query) => {
  return request({
    url: '/business/website/listDownload',
    method: 'get',
	params: query
  })
}
export const getlistCity = () => {
  return request({
    url: '/business/website/listCity',
    method: 'get'
  })
}
export const getlistDirection = () => {
  return request({
    url: '/business/website/listDirection',
    method: 'get'
  })
}