<template>
  <div class="container" >
    <!-- header :style="{ width: clintWidth + 'px', height: clintHeight + 'px' }" -->
    <div>
      <HeaderTop @navClick="$refs.MenuListRef.init();$refs.BreadCrumbRef.init()"/>

    </div>
    <div class="container-shell">
      <div class="sub-banner">
        <img src="~@/assets/img/subBanner2.png" alt="" class="width-fill height-fill">
      </div>
      <div class="right-shell">
        <MenuList ref="MenuListRef" @menuClick="$refs.BreadCrumbRef.init()"/>
        <div class="content-shell">
          <BreadCrumb ref="BreadCrumbRef"/>
					<div class="content">
						<div class="list-shell">
							<div class="item-shell flex-start" v-for="item in newsList" :key="item.id">
								<img class="item-image" :src="imgBaseUrl.imgBaseUrl+item.photo" alt="">
								<div class="item-right">
                  <div class="item-title">
                    {{item.title}}
                  </div>
                  <div class="item-desc">
                    {{item.remark}}
                  </div>
                  <div class="item-footer flex-between">
                   <router-link class="toDetail" :to="'/listIndeduDetail/'+item.id" tag="span"> 了解详情+ </router-link>
                    <span>{{ item.createTime }}</span>
                  </div>
                </div>
							</div>
							
							
						</div>
					</div>
					<div class="flex-between margin-top-sm">
						<span></span>
						<el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :hide-on-single-page="true" background layout="prev, pager, next" :page-size="queryParams.pageSize" :total="total" > </el-pagination>
					</div>
        </div>
      </div>
      <BottomFooter />
    </div>

  </div>
</template>
<script>
import HeaderTop from "@/components/parts/headerTop";
import BottomFooter from "@/components/parts/bottomFooter";
import MenuList from "@/components/parts/menuList";
import BreadCrumb from "@/components/parts/BreadCrumb";
// import DynamicMarquee from "vue-dynamic-marquee";
import { getlistIndedu } from '@/api/api';
import imgBaseUrl from '/utils/imgBaseUrl';
export default {
  name: "listIndedu",
  data() {
    return {
		queryParams: {
		  pageNum: 1,
		  pageSize: 6
				
		},
		total:0,
		currentPage:1,
      imgBaseUrl,
      clintWidth: 1920,
      clintHeight: 1080,
      newsList: [
      ],
      subbannerSrc:'',
      tab: 0
    }
  },
  components: {
    HeaderTop,
    BottomFooter,
    MenuList,
    BreadCrumb
  },
  mounted() {
    this.menu = StorageUtil.get('currentRouter')
    this.$nextTick(() => {
      this.subbannerSrc = `assets/img/subBanner${this.menu.id}.png`
    })


  },
  created(){
  	  this.getlistIndedu();
  },
  methods: {
	  handleCurrentChange(currentPage) {
	  	this.queryParams.pageNum = currentPage;
	  	 getlistIndedu(this.queryParams).then(res => {
	  			this.newsList = res.rows;
	  			this.total = res.total;
	  	 })
	  },
    getlistIndedu(){
      getlistIndedu(this.queryParams).then(res => {
    	this.newsList = res.rows;
		this.total = res.total;
      })
    },
  },
  destroyed() {

  }
};
</script>
<style scoped lang="less">
	/deep/ .el-pagination{
	  &.is-background .btn-next, &.is-background .btn-prev, &.is-background .el-pager li {
	    background-color: transparent;
	    border: 1px solid #DDD;
	    font-weight: 400;
	  }
	  &.is-background .el-pager li:not(.disabled).active{
	    background-color: #CC3333;
	    border: none;
	  }
	}
  .container {
		width: 100%;
		min-height: 100vh;
    .container-shell {
      height: calc(100% - 0);
      overflow-y: scroll;
    }
    .sub-banner{
      width: 100%;
      height: 248px;
    }
    .right-shell{
      width: 1420px;
      min-height: 560px;
      padding-left: 300px;
      position: relative;
      margin: 0 auto;
      & .content-shell{
        width: 100%;
        // height: 100%;
    		.content {
    			width: 100%;

					.list-shell{
						overflow-x: hidden;
						overflow-y: scroll;
						width: 100%;
            .item-shell {
              padding: 20px 0;
              border-bottom: 1px dashed #ddd;
              position: relative;
              height: 240px;
              .item-right{
                width: calc(100% - 375px);
                height: 100%;
                position: relative;
              }
              .item-footer{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
              }
              .toDetail{
                color: #CC3333;
				cursor:pointer;
              }
            }
            .item-image{
              width: 350px;
              height: 205px;
              margin-right: 24px;
            }
						.item-title{
							color: #333;
							line-height: 2em;
							font-size: 16px;
							position: relative;

						}
            .item-desc {
              color: #666;
              font-size: 16px;
              text-align: justify;
              text-indent: 2em;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5;
            }
						.item-time {
							text-align: right;
							padding-right: 25px;
							width: 20%;
							color: #676767;
							font-size: 16px;
						}
					}
    		}
      }
    }
  }

</style>
