<template>
  <div class="container" >
    <!-- header :style="{ width: clintWidth + 'px', height: clintHeight + 'px' }" -->
    <div>
      <HeaderTop @navClick="$refs.MenuListRef.init();$refs.BreadCrumbRef.init()"/>

    </div>
    <div class="container-shell">
      <div class="sub-banner"><img src="~@/assets/img/subBanner5.png" alt="" class="width-fill height-fill"></div>
      <div class="right-shell">
        <MenuList ref="MenuListRef" @menuClick="$refs.BreadCrumbRef.init()"/>
        <div class="content-shell">
          <BreadCrumb ref="BreadCrumbRef"/>
					<div class="content">
						<div class="list-shell flex-wrap margin-top-xl">
							<div class="item-shell flex-start" v-for="item in newsList" :key="item.id" @click="toDetail(item.id)">
<!--								<router-link  :to="'/listMembershipStyleDetail/'+item.id" tag="span">-->
									<img class="item-image" :src="imgBaseUrl.imgBaseUrl+item.photo" alt="">
									<div class="hy-title">{{ item.title }}</div>
<!--								</router-link>-->
							</div>
						</div>
					</div>
					<div class="flex-between margin-top-sm">
						<span></span>
						<el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :hide-on-single-page="true" background layout="prev, pager, next" :page-size="queryParams.pageSize" :total="total" > </el-pagination>
					</div>
        </div>
      </div>
      <BottomFooter />
    </div>

  </div>
</template>
<script>
import HeaderTop from "@/components/parts/headerTop";
import BottomFooter from "@/components/parts/bottomFooter";
import MenuList from "@/components/parts/menuList";
import BreadCrumb from "@/components/parts/BreadCrumb";
import { getlistMembershipStyle } from '@/api/api';
import imgBaseUrl from '/utils/imgBaseUrl';
// import { getEqpTotal, getEqpList, getOkNgOutput, getEqpOkNgOutput, getHisEqpShiftList, getOeeEqpOutputList, getHisOeeEqpHisHourseOutput } from '@/api/api'
export default {
  name: "sysList6",
  data() {
    return {
      imgBaseUrl,
      clintWidth: 1920,
      clintHeight: 1080,
      newsList: [
      ],
	  queryParams: {
	    pageNum: 1,
	    pageSize: 9
	  },
		total:0,
		currentPage:1,
      tab: 0
    }
  },
  components: {
    HeaderTop,
    BottomFooter,
    MenuList,
    BreadCrumb
  },
  mounted() {
    // this.resize();


  },
 created(){

 	  this.getlistMembershipStyle();
 },
 methods: {
  toDetail(id) {
      this.$router.replace({
          path: '/listMembershipStyleDetail/' + id,
      })
  },
	 handleCurrentChange(currentPage) {
	 	this.queryParams.pageNum = currentPage;
	 	 getlistMembershipStyle(this.queryParams).then(res => {
	 			this.newsList = res.rows;
	 			this.total = res.total;
	 	 })
	 },
   getlistMembershipStyle(){
     getlistMembershipStyle(this.queryParams).then(res => {
   	this.newsList = res.rows;
		this.total = res.total;
     })
   },
 },
  destroyed() {

  }
};
</script>
<style scoped lang="less">
	/deep/ .el-pagination{
	  &.is-background .btn-next, &.is-background .btn-prev, &.is-background .el-pager li {
	    background-color: transparent;
	    border: 1px solid #DDD;
	    font-weight: 400;
	  }
	  &.is-background .el-pager li:not(.disabled).active{
	    background-color: #CC3333;
	    border: none;
	  }
	}
  .container {
		width: 100%;
		min-height: 100vh;
    .container-shell {
      height: calc(100% - 0);
      overflow-y: scroll;
    }
    .sub-banner{
      width: 100%;
      height: 248px;
    }
    .right-shell{
      width: 1420px;
      min-height: 560px;
      // height: 100%;
      padding-left: 300px;
      position: relative;
      margin: 0 auto;
      & .content-shell{
        width: 100%;
        // height: 100%;
    		.content {
    			width: 100%;

					.list-shell{
						overflow-x: hidden;
						overflow-y: scroll;
						width: calc(100% + 24px);
            .item-shell {
              width: 350px;
              height: 280px;
              margin-right: 24px;
              margin-bottom: 24px;
              position: relative;
			  cursor:pointer;
              .hy-title{
                text-align: center;
                color: #fff;
                line-height: 34px;
                background: #CC3333;
                position: absolute;
                bottom: 0;
                width: 100%;
              }
            }
            .item-image{
              width: 100%;
              height: 100%;
            }
						.item-title{
							color: #333;
							line-height: 2em;
							font-size: 16px;
							position: relative;

						}
            .item-desc {
              color: #666;
              font-size: 16px;
              text-align: justify;
              text-indent: 2em;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5;
            }
						.item-time {
							text-align: right;
							padding-right: 25px;
							width: 20%;
							color: #676767;
							font-size: 16px;
						}
					}
    		}
      }
    }
  }

</style>
