<template>
  <div class="container" >
    <!-- header :style="{ width: clintWidth + 'px', height: clintHeight + 'px' }" -->
    <div>
      <HeaderTop @navClick="$refs.MenuListRef.init();$refs.BreadCrumbRef.init()"/>

    </div>
    <div class="container-shell">
      <div class="sub-banner"><img src="~@/assets/img/subBanner3.png" alt="" class="width-fill height-fill"></div>
      <div class="right-shell">
        <MenuList ref="MenuListRef" @menuClick="$refs.BreadCrumbRef.init()"/>
        <div class="content-shell">
          <BreadCrumb ref="BreadCrumbRef"/>
					<div class="content">
						<div class="list-shell">
							<div class="item-shell flex-between" v-for="item in newsList" :key="item.id">
								<!-- <div class=""></div> -->
								<div class="item-title">
									<router-link  :to="'/listPolicyStatuteDetail/'+item.id" tag="span"> {{item.title}} </router-link>
								</div>
								<div class="item-time">
									{{item.createTime}}
								</div>
							</div>
						</div>
					</div>
					<div class="flex-between margin-top-sm">
						<span></span>
						<el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :hide-on-single-page="true" background layout="prev, pager, next" :page-size="queryParams.pageSize" :total="total" > </el-pagination>
					</div>
        </div>
      </div>
      <BottomFooter />
    </div>

  </div>
</template>
<script>
import HeaderTop from "@/components/parts/headerTop";
import BottomFooter from "@/components/parts/bottomFooter";
import MenuList from "@/components/parts/menuList";
import BreadCrumb from "@/components/parts/BreadCrumb";
import { getlistPolicyStatute } from '@/api/api'
export default {
  name: "listPolicyStatute",
  data() {
    return {
		queryParams: {
		  pageNum: 1,
		  pageSize: 10
				
		},
		total:0,
		currentPage:1,
      clintWidth: 1920,
      clintHeight: 1080,
      newsList: [
      ],

      tab: 0
    }
  },
  components: {
    HeaderTop,
    BottomFooter,
    MenuList,
    BreadCrumb
  },
  mounted() {
    // this.resize();


  },

 created(){
 	  this.getlistPolicyStatute();
 },
 methods: {
	 handleCurrentChange(currentPage) {
	 	this.queryParams.pageNum = currentPage;
	 	 getlistPolicyStatute(this.queryParams).then(res => {
	 			this.newsList = res.rows;
	 			this.total = res.total;
	 	 })
	 },
   getlistPolicyStatute(){
     getlistPolicyStatute(this.queryParams).then(res => {
   	this.newsList = res.rows;
	this.total = res.total;
     })
   },
 },
  destroyed() {

  }
};
</script>
<style scoped lang="less">
	/deep/ .el-pagination{
	  &.is-background .btn-next, &.is-background .btn-prev, &.is-background .el-pager li {
	    background-color: transparent;
	    border: 1px solid #DDD;
	    font-weight: 400;
	  }
	  &.is-background .el-pager li:not(.disabled).active{
	    background-color: #CC3333;
	    border: none;
	  }
	}
  .container {
		width: 100%;
		min-height: 100vh;
    .container-shell {
      height: calc(100% - 0);
      overflow-y: scroll;
    }
    .sub-banner{
      width: 100%;
      height: 248px;
    }
    .right-shell{
      width: 1420px;
      min-height: 560px;
      padding-left: 300px;
      position: relative;
      margin: 0 auto;
      & .content-shell{
        width: 100%;
        height: 100%;
    		.content {
    			width: 100%;
					overflow-x: hidden;
					.list-shell{
						overflow-x: hidden;

						width: calc(100% + 20px);
						.item-title{

							color: #333;
							line-height: 2em;
							font-size: 18px;
							position: relative;
							border-bottom: 1px dashed #DDDDDD;
							padding: 6px 0 4px 20px;
							width: 80%;
							&::before{
								content: "";
								display: block;
								width: 12px;
								height: 12px;
								border-radius: 50%;
								position: absolute;
								left: 0;
								top: 16px;
								background: #CC3333;
							}
							cursor:pointer;
						}
						.item-time {
							text-align: right;
							padding-right: 25px;
							width: 20%;
							color: #676767;
							font-size: 16px;
						}
					}
    		}
      }
    }
  }

</style>
