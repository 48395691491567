export default [
  {
    id: 0,
    name: '首页',
    url: '/',
  },
  {
    id: 1,
    name: '促进会概况',
    url: '/sysList',
    children: [
      {
        id: '01',
        name: '促进会简介',
        url: '/sysInfo',
      },
      {
        id: '02',
        name: '机构设置',
        url: '/Institution',
      },
      {
        id: '03',
        name: '促进会章程',
        url: '/Constitution',
      },
      {
        id: '04',
        name: '专家委员会',
        url: '/listExpert',
      },
{
  id: '05',
  name: '专业委员会',
  url: '/Specialization',
},
      {
        id: '06',
        name: '联系我们',
        url: '/Address',
      },
    ],
  },
  {
    id: 2,
    name: '动态资讯',
    url: '/listAssociation',
    children: [
      {
        id: '01',
        name: '促进会动态',
        url: '/listAssociation',
      },
      {
        id: '02',
        name: '产教动态',
        url: '/listIndedu',
      },
      {
        id: '03',
        name: '通知公告',
        url: '/listNotice',
      },
    ],
  },
  {
    id: 3,
    name: '政策资讯',
    children: [
      {
        id: '01',
        name: '政策法规',
        url: '/listPolicyStatute',
      },
      {
        id: '02',
        name: '专题报道',
        url: '/listSpecial',
      },
      {
        id: '03',
        name: '试点分布',
        url: '/listExperimental',
      },
    ],
  },
  {
    id: 4,
    name: '党建专栏',
    children: [
      {
        id: '01',
        name: '党建工作',
        url: '/listBuilding',
      },
    ],
  },
  {
    id: 5,
    name: '会员风采',
    children: [
      {
        id: '01',
        name: '会员风采',
        url: '/listMembershipStyle',
      },
      {
        id: '02',
        name: '会员名录',
        url: '/listMembershipStyle2',
      },
    ],
  },
  {
    id: 6,
    name: '需求对接',
    children: [
      // {
      //   id: '01',
      //   name: '行业需求',
      //   url: '/listIndustry',
      // },
      {
        id: '02',
        name: '企业需求',
        url: '/listCompany',
      },
      {
        id: '03',
        name: '院校需求',
        url: '/listAcademy',
      },
    ],
  },
  {
    id: 7,
    name: '资料下载',
    children: [
      {
        id: '01',
        name: '资料下载',
        url: '/listDownload',
      },
    ],
  },
  {
    id: 8,
    name: '服务平台',
    url: 'https://www.sdscjrh.org.cn:8888/'
  },
]